import React, { useEffect, useRef, useState } from "react";
import { apiConnector } from "../../services/apiconnector";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import image from "../../Images/profile-picture-3.jpg";
import {
  IoCloseCircle,
  IoCloseCircleOutline,
  IoLocationSharp,
  IoShareOutline,
} from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import ProfileSkeletonLoading from "../../Pages/NewUI/ProfileSkeletonLoading";
import Reviews from "../../Components/UserProfile/Reviews";
import { PostCard } from "../../Components/Dashboard/NewUI/PostCard";
import AltImage from "../../Images/profile-picture-3.jpg";
import { ProfileRecommendationCard } from "../../Components/UserProfile/ProfileRecommendationCard";
import Popup from "reactjs-popup";
import { IoPauseSharp, IoPlaySharp } from "react-icons/io5";
import { PauseProfile } from "../../Components/UserProfile/PauseProfile";
import { PencilIcon } from "@heroicons/react/24/outline";
import Modal from "../../Components/UserProfileByID/Modal";
import { ReviewCard } from "../../Components/UserProfileByID/ReviewCard";
import { FiX } from "react-icons/fi";
import ShareProfileCard from "../../Components/UserProfile/ShareProfileCard";
import { SharePost } from "../../Components/Dashboard/NewUI/SharePost";
import { ShareProfileCardNew } from "../../Components/UserProfileByID/ShareProfileCardNew";
import { MdVerified } from "react-icons/md";
import VerifyUser from "./VerifyUser";
import UploadAvatarModal from "../../Components/Register/UploadAvatarModal";

export const UserProfileByID = () => {
  const [token, setToken] = useState(true);

  const TokenHandler = () => {
    setToken(!token);
  };

  const location = useLocation();

  // const [isLoading, setLoading] = useState(false);
  const [userProfileData, setUserProfileData] = useState(null);
  const [recommendationData, setRecommendationData] = useState(null);
  const [error, setError] = useState(null);
  const [recommendationError, setRecommendationError] = useState(null);
  const [profileLoading, setProfileLoading] = useState(false);

  const username = location.pathname.split("/").at(-1);
  console.log(`Username ${username}`);

  const [postCount, setPostCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);

  // const {profile} = useSelector((state) => state.profile);
  const [pageNo, setPageNo] = useState(1);

  const [body, setBody] = useState(
    "This user has not received any reviews yet"
  );

  const [showFullBody, setShowFullBody] = useState(false);
  const MAX_WORDS = 15;
  var words = body.split(" ");
  const truncatedBody = words.slice(0, MAX_WORDS).join(" ");
  const isTruncated = words.length > MAX_WORDS;

  const toggleBody = () => {
    setShowFullBody(!showFullBody);
  };

  const fetchUserData = async () => {
    setProfileLoading(true);
    try {
      const responseData = await apiConnector(
        "GET",
        `https://api.atmirrors.com/users/profile/${username}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      console.log("User data Received", responseData.data.data);

      setUserProfileData(responseData.data.data);
      setPostCount(responseData.data.data.postCount);
      setReviewCount(responseData.data.data.reviewCount);

      if (
        responseData.data.data.reviews &&
        responseData.data.data.reviews.length > 0
      ) {
        setBody(responseData.data.data.reviews[0].body);
      } else {
        setBody("This user has not received any reviews yet");
      }
    } catch (error) {
      console.log("error", error);
      setError("Error fetching user data. Please try again later.");
    } finally {
      setProfileLoading(false);
    }
  };

  // console.log("PostCount", userProfileData.postCount);
  // console.log("reviewCount", userProfileData.reviewCount);

  // const fetchRecommendations = async () => {
  //   setProfileLoading(true);
  //   try {
  //     const responseData = await apiConnector(
  //       "GET",
  //       `https://mirror-express-server.onrender.com/api/v1/profileRecommendations?page=${pageNo}`,
  //       null,
  //       { Authorization: `Bearer ${token}` }
  //     );
  //     console.log("Recommendations data Received", responseData);

  //     setRecommendationData(responseData.data.recommendations);
  //   } catch (error) {
  //     console.log("error", error);
  //     setRecommendationError(
  //       "Error fetching user data. Please try again later."
  //     );
  //   } finally {
  //     setProfileLoading(false);
  //   }
  // };
  const closeModal = () => {};

  const { user } = useSelector((state) => state.profile);
  // console.log("User in the UserProfileByid", user);

  useEffect(() => {
    fetchUserData();
    console.log("User data fetched");
    // fetchRecommendations();
  }, [username, user]);

  //custom data
  const currentCompany = "current Company";
  const bio = "About section";
  const userLocation = "India";
  const tempReview = "This is Review";

  const [showAllReviews, setShowAllReviews] = useState(false);
  const [showAllPosts, setShowAllPosts] = useState(false);

  const renderReviews = () => {
    const reviewsToRender = showAllReviews
      ? userProfileData.reviews
      : userProfileData.reviews.slice(0, 2);

    // console.log("Reviews Lenght", reviewsToRender.length());

    return (
      <div className="lg:w-[48rem]  p-1 pt-0">
        {reviewsToRender.map((review, index) => (
          <PostCard
            key={review.id}
            Name={userProfileData?.fullName || "NA"}
            CompanyName={userProfileData?.profile?.company || "NA"}
            image={userProfileData?.userImage || "defaultImageURL"}
            postId={review?.id || "NA"}
            postTitle={review?.title || "Untitled"}
            createdAt={review?.createdAt || "Unknown Date"}
            updatedAt={review?.updatedAt || "Unknown Date"}
            body={review?.body || "No content"}
            authorName={review?.author?.fullName || "Anonymous"}
            upvoteCount={review?.upVoteCount || 0}
            downVoteCount={review?.downVoteCount}
            isAnonymous={review?.isAnonymous || false}
            authorUserName={review?.author?.username || "Unknown User"}
            authorImage={review?.author?.userImage || "defaultAuthorImageURL"}
            reviewedUserRating={review?.reviewedUserRating || "Unrated"}
            reviewedUserUserName={userProfileData?.username || "NA"}
            voteType={review?.voteType || "No Vote"}
            hasUserVoted={false}
            // hasUserVoted={review?.hasUserVoted || false}
            currentCompany={userProfileData.profile.company || "NA"}
            currentRole={userProfileData?.profile?.workRole}
            selectedSection={selectedSection}
          />
        ))}
      </div>
    );
  };

  const handleSeeMoreReviews = () => {
    setShowAllReviews(true);
  };

  const renderPosts = () => {
    const postsToRender = showAllPosts
      ? userProfileData.posts
      : userProfileData.posts.slice(0, 2);

    // console.log(postsToRender[0].reviewedUser.profile.company);

    return (
      <div className="lg:w-[48rem] p-1 pt-0 ">
        {postsToRender.map((post, index) => (
          <PostCard
            key={post.id}
            Name={post?.reviewedUser.fullName}
            image={post?.reviewedUser.userImage || AltImage}
            postId={post?.id}
            postTitle={post?.title}
            createdAt={post?.createdAt}
            updatedAt={post?.updatedAt}
            body={post?.body}
            authorName={userProfileData.fullName}
            upvoteCount={post?.upVoteCount || 0}
            downVoteCount={post?.downVoteCount || 0}
            isAnonymous={post?.isAnonymous || false}
            authorUserName={userProfileData?.username || ""}
            reviewedUserUserName={post?.reviewedUser.username || ""}
            authorImage={userProfileData.userImage || AltImage}
            reviewedUserRating={post?.reviewedUserRating || 0}
            hasUserVoted={post?.hasUserVoted || false}
            voteType={post?.voteType}
            currentCompany={post?.reviewedUser.profile.company || "NA"}
            currentRole={post?.reviewedUser.profile.workRole || "NA"}
          />
        ))}
      </div>
    );
  };

  const handleSeeMorePosts = () => {
    setShowAllPosts(true);
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickSignOut = () => {
    setDropdownVisible(false);
    setShowModal(true);
  };

  const [openEdit, setOpenEdit] = useState(false);
  const closeModalEdit = () => setOpenEdit(false);

  const navigate = useNavigate();

  const reviewRedirectHandler = () => {
    navigate(
      `/post?user=${username}&name=${userProfileData.fullName}&img=${userProfileData.userImage}`
    );
  };

  const profile = useSelector((state) => state.profile);
  // console.log("LoggedIn User", profile);

  const [modalOpen, setModalOpen] = useState(false);
  const avatarUrl = useRef(image);
  const updateAvatar = (imgSrc) => {
    avatarUrl.current = imgSrc;
  };

  const [selectedSection, setSelectedSection] = useState("reviews");

  // console.log("profile.user._id", profile.user._id);

  // const trimmedUserLocation = data.additionalDetails.location.split(/\s+/)[0];

  const handleVerifyUser = () => {
    navigate(`/anonymous-verification/email`);
    // navigate(`/user-verification/${username}`);
  };

  // console.log(recommendationData);

  return (
    <div className="pt-[5.5rem] mb-[5.5rem]">
      {profileLoading ? (
        <ProfileSkeletonLoading />
      ) : error ? (
        (<div>{error}</div>)(
          <div className="flex h-full w-full flex-col gap-4 items-center justify-center">
            <div class="spinner"></div>
            <div className="pb-[100px] font-medium ">Loading</div>
          </div>
        )
      ) : error ? (
        <div>{error}</div>
      ) : userProfileData ? (
        <div className="flex flex-col justify-center items-center ">
          <div className="flex flex-col w-full max-w-[345px] sm:max-w-[650px] lg:max-w-[45rem]  border-b-2 rounded-md mx-auto">
            <div className="flex justify-end w-full ">
              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                  type="button"
                  aria-expanded={dropdownVisible}
                  aria-controls="dropdownComment"
                >
                  <BsThreeDots color="black" size={15} />
                </button>
                {dropdownVisible && (
                  <div
                    id="dropdownComment"
                    className="absolute right-0 z-10 mt-1 bg-white rounded divide-y divide-gray-100 shadow"
                    style={{ width: "170px" }}
                  >
                    <ul
                      className=" text-sm text-gray-700 flex  flex-col gap-2 justify-center items-start "
                      aria-labelledby="dropdownMenuIconHorizontalButton"
                    >
                      {/* {userProfileData?.username ===
                        profile?.user?.username && (
                        <li>
                          <div className="block py-1 hover:bg-gray-100">
                            <button onClick={handleClickSignOut}>
                              <div className="flex flex-row gap-1">
                                {isPaused ? (
                                  <IoPlaySharp className="mt-1" />
                                ) : (
                                  <IoPauseSharp className="mt-1" />
                                )}{" "}
                                {isPaused
                                  ? "Resume your profile"
                                  : "Pause your profile"}
                              </div>
                            </button>
                          </div>
                        </li>
                      )} */}

                      <li>
                        <Popup
                          trigger={
                            <button>
                              <div className="flex gap-2 justify-center items-center">
                                <span>
                                  <IoShareOutline size={15} />
                                </span>
                                <span>Share Profile</span>
                              </div>
                            </button>
                          }
                          closeOnEscape
                          closeOnDocumentClick
                          modal
                          contentStyle={{
                            width: "365px",
                            height: "auto",
                            maxHeight: "80vh",
                            overflowY: "auto",
                            font: "inherit",
                          }}
                        >
                          {(close) => (
                            <div>
                              <button
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                }}
                                onClick={close}
                              >
                                <FiX />
                              </button>
                              <ShareProfileCardNew />
                            </div>
                          )}
                        </Popup>
                      </li>

                      <li>
                        {userProfileData?.username ===
                          profile?.user?.username && (
                          <button
                            onClick={handleVerifyUser}
                            className="flex gap-2 justify-center items-center"
                          >
                            <span>
                              <MdVerified size={15} />
                            </span>
                            <span>Verify Profile</span>
                          </button>
                        )}
                      </li>

                      <li>
                        <button onClick={TokenHandler}>
                          <div className="flex gap-2 justify-center items-center">
                            {token ? (
                              <IoPlaySharp className="" />
                            ) : (
                              <IoPauseSharp className="mt-0.5" />
                            )}{" "}
                            {token ? "Blur Effect ON" : "Blur Effect OFF"}
                          </div>
                        </button>
                      </li>
                    </ul>
                  </div>
                )}

                <PauseProfile
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setIsPaused={setIsPaused}
                  isPaused={isPaused}
                />
              </div>
            </div>

            <div className="flex w-full items-center gap-2">
              {/* Profile Image */}
              <div className="w-[50%] flex justify-center">
                <div className="relative">
                  <Popup
                    trigger={
                      <button
                        title="view image"
                        className="hover:border-black rounded-full"
                      >
                        <img
                          className="hover:border-black lg:w-[160px] lg:h-[160px] w-[140px] h-[140px] hover:shadow-lg hover:border rounded-full hover:scale-105 transform-gpu duration-75 ease-out"
                          src={userProfileData?.userImage || AltImage}
                        />
                      </button>
                    }
                    modal
                    closeOnEscape
                    closeOnDocumentClick
                    contentStyle={{
                      width: "300px",
                      height: "auto",
                      maxHeight: "80vh",
                      overflowY: "auto",
                    }}
                  >
                    {(close) => (
                      <div>
                        <div className="flex justify-end">
                          <button type="button">
                            <IoCloseCircleOutline
                              type="button"
                              onClick={close}
                              size={25}
                            />
                          </button>
                        </div>

                        <div className="flex flex-col items-center justify-center m-5">
                          <img
                            src={userProfileData?.userImage}
                            className="lg:w-[100%] lg:h-[100%] w-[90%] h-[90%] hover:shadow-lg rounded-md"
                          />

                          {profile.user?.username ===
                            userProfileData.username && (
                            <div className="mt-10">
                              <button
                                className="border hover:bg-black hover:text-white p-2 px-5 rounded-full"
                                onClick={() => {
                                  setModalOpen(true);
                                  close();
                                }}
                              >
                                <span>Edit Profile Image</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Popup>

                  {userProfileData?.username === profile?.user?.username && (
                    <button
                      className="w-7 bg-white absolute -bottom-3 left-0 right-0 m-auto p-[.35rem] rounded-full hover:bg-black border border-gray-600 hover:text-white"
                      title="Change photo"
                      onClick={() => setModalOpen(true)}
                    >
                      <PencilIcon className="hover:text-white" />
                    </button>
                  )}
                </div>
              </div>

              {modalOpen && (
                <UploadAvatarModal
                  updateAvatar={updateAvatar}
                  closeModal={() => setModalOpen(false)}
                />
              )}

              {/* Profile Content */}
              <div className="flex flex-col w-[50%] justify-start">
                <div className="flex items-start justify-start flex-col mb-2">
                  <div className="font-bold lg:text-xl">
                    {userProfileData.fullName}
                  </div>
                  <div className="font-light text-xs text-blue-600">
                    @{userProfileData.username}
                  </div>
                </div>

                <div
                  className="lg:font-medium text-start text-sm"
                  title="Current Company"
                >
                  {userProfileData.profile.company}{" "}
                </div>

                <div
                  className="lg:font-medium text-start text-sm"
                  title="Current Role"
                >
                  {userProfileData.profile.workRole}
                </div>

                <div
                  className="flex items-center justify-start gap-1"
                  title="location"
                >
                  <div className="pt-0.5 lg:font-medium lg:text-sm text-sm text-left">
                    {userProfileData.profile.location}
                  </div>
                </div>
              </div>
            </div>

            {/* Rating Card */}
            <div className="bg-red-40 flex mt-5 mb-5" title="Mirror Rating">
              {userProfileData.averageRating === 0 ? (
                <div className="w-[50%] flex flex-col gap-2 items-center justify-center font-semibold text-sm">
                  <span>No reviews yet</span>
                </div>
              ) : (
                <div className="w-[50%] flex flex-col items-center justify-center">
                  <div className="flex justify-center items-center gap-1">
                    <span className="text-3xl font-semibold">
                      {userProfileData.averageRating}
                    </span>
                    <span>
                      <FaStar />
                    </span>
                  </div>

                  <p className="text-xs">
                    ({userProfileData.reviews.length} Reviews)
                  </p>
                </div>
              )}

              <div className="w-[50%]" title="Pinned Top Review">
                <p className="text-left text-sm">
                  {isTruncated && !showFullBody ? (
                    <>
                      {truncatedBody}...
                      <button
                        className="text-blue-500 text-xs"
                        onClick={toggleBody}
                      >
                        See more
                      </button>
                    </>
                  ) : (
                    <>
                      {body}
                      {showFullBody && (
                        <button
                          className="text-blue-500 text-xs"
                          onClick={toggleBody}
                        >
                          See less
                        </button>
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* <hr className="font-bold w-full mt-5" /> */}

          {!token ? (
            <button
              disabled
              className="border mt-5 w-[325px] lg:h-[50px] h-[40px]  rounded-full hover:bg-gray-100 cursor-not-allowed"
            >
              <span>
                Please log in to review{" "}
                {userProfileData?.fullName?.split(" ")[0]}
              </span>
            </button>
          ) : userProfileData?.username === profile?.user?.username ? (
            <button
              className="bg-black mt-5 w-[325px] lg:h-[50px] h-[40px] text-white rounded-full hover:bg-gray-900"
              onClick={() => navigate("/settings/updateProfileDetails")}
            >
              <span>Update Your Profile</span>
            </button>
          ) : (
            <button
              className="bg-black mt-5 w-[325px] lg:h-[50px] h-[40px] text-white rounded-full hover:bg-gray-900"
              onClick={reviewRedirectHandler}
            >
              <span>
                Write a Review for {userProfileData?.fullName?.split(" ")[0]}
              </span>
            </button>
          )}

          <hr className="font-bold w-full mt-5" />

          <div className="">
            {/* About Section */}
            <div className="relative mt-2 p-1 w-full max-w-[345px] sm:max-w-[650px] lg:max-w-[45rem] flex flex-col items-start justify-start gap-2">
              <div className={`font-bold text-2xl ${!token ? "blur-sm" : ""}`}>
                About
              </div>
              <p className={`text-left ${!token ? "blur-sm" : ""}`}>
                {userProfileData.profile.about || bio}
              </p>

              {!token && (
                <div className="absolute inset-0 flex items-center justify-center bg-white/40 rounded-lg z-10  ">
                  <span className="text-lg font-semibold text-gray-800 hover:duration-100 hover:underline  p-2 rounded-md transition-opacity	hover:ease-out">
                    Login to view more details
                  </span>
                </div>
              )}
            </div>

            {/* Reviews Section */}
            <div className="relative mt-5 w-full max-w-[345px] sm:max-w-[650px] lg:max-w-[45rem]">
              {!token && (
                <div className="absolute inset-0 flex items-center justify-center bg-white/40 rounded-lg z-10  w-full ">
                  <span className="text-lg font-semibold text-gray-800  hover:border-black hover:duration-100 hover:underline b p-5 rounded-md transition-opacity	hover:ease-out">
                    Login to view reviews
                  </span>
                </div>
              )}

              {/* Navigation Tabs */}
              <div className="flex items-start lg:justify-center lg:gap-10 p-2 justify-around font-semibold text-md">
                <div
                  onClick={() => setSelectedSection("reviews")}
                  className={`cursor-pointer ${
                    selectedSection === "reviews"
                      ? "border-b-2 lg:border-b-4 border-gray-900"
                      : ""
                  }`}
                >
                  Reviews
                </div>
                <div
                  onClick={() => setSelectedSection("posts")}
                  className={`cursor-pointer ${
                    selectedSection === "posts"
                      ? "border-b-2 lg:border-b-4 border-gray-900"
                      : ""
                  }`}
                >
                  Posts
                </div>
                {userProfileData?.username === profile?.user?.username && (
                  <div
                    onClick={() => setSelectedSection("saved")}
                    className={`cursor-pointer ${
                      selectedSection === "saved"
                        ? "border-b-2 lg:border-b-4 border-gray-900"
                        : ""
                    }`}
                  >
                    Saved
                  </div>
                )}
              </div>
              <hr className="h-px bg-gray-300 border-0" />

              {/* Reviews or Posts */}
              <div className={`mt-4 mb-8 ${!token ? "blur-sm" : ""}`}>
                {selectedSection === "reviews" ? (
                  <>
                    {renderReviews()}
                    {reviewCount ? (
                      !showAllReviews &&
                      reviewCount > 2 && (
                        <button
                          className="font-semibold text-sm text-blue-500 mb-[1rem]"
                          onClick={handleSeeMoreReviews}
                        >
                          See More Reviews...
                        </button>
                      )
                    ) : (
                      <div>No Reviews Available</div>
                    )}
                  </>
                ) : (
                  <>
                    {renderPosts()}
                    {postCount
                      ? !showAllPosts &&
                        postCount > 2 && (
                          <button
                            className="font-semibold text-sm text-blue-500 mb-[1rem]"
                            onClick={handleSeeMorePosts}
                          >
                            See More Posts...
                          </button>
                        )
                      : "No Posts Available"}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* horizontal Profile Recommendation */}
          {/* <div className="font-bold text-lg text-start lg:w-6/12 w-11/12 ml-2 mt-5">
            Recommended Profiles{" "}
          </div>
          <div className="flex gap-4 sticky mt-5 w-full max-w-[345px] sm:max-w-[45rem] lg:max-w-[45rem] overflow-x-auto whitespace-nowrap">
            {recommendationData?.map((profile) => {
              if (profile._id === username) {
                return null;
              }

              return (
                <ProfileRecommendationCard
                  key={profile._id}
                  userId={profile._id}
                  fullName={profile.fullName}
                  image={profile.image}
                  currentCompany={profile.profileDetails.currentCompany || "NA"}
                  currentRating={profile.averageRating}
                  reviews={profile.totalReviews}
                />
              );
            })}
          </div> */}
        </div>
      ) : null}
    </div>
  );
};
